import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import ClipboardCopy from '../../../components/worksafe-vic/ClipboardCopy';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h1>
    <h3 {...{
      "id": "development-machines-simulators-and-environments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#development-machines-simulators-and-environments",
        "aria-label": "development machines simulators and environments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Development machines, simulators and environments`}</h3>
    <p>{` Tyro makes available development CounterTop machines for partner integration and testing efforts. Send a note to `}<a parentName="p" {...{
        "href": "mailto:parternships@medipass.io"
      }}>{`parternships@medipass.io`}</a>{` if you have not received your test machine. Included with the machine are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Test payment cards`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Test Private Health Fund cards`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Paper for receipts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Details of test MIDs and TIDs`}</p>
        <p parentName="li">{`Note that only test EFTPOS and health fund cards will work on development terminals - production cards will not function.
A physical machine is not required for development - a simulator can be used for most functions which does not require the entry/swipe of test cards.
In either case, a list of test TIDs and MIDs will be sent to each partner. Please ensure these details are used for testing.
For testing, a dedicated integration testing environment has been established with stubbed responses for various transaction scenarios. This is called the Medipass STG environment.`}</p>
        <p parentName="li">{`Prior to testing, partners will need to have a non-production test business setup in the Medipass STG environment. For claiming, the business will require at least one activated provider. Send a note to `}<a parentName="p" {...{
            "href": "mailto:parternships@medipass.io"
          }}>{`parternships@medipass.io`}</a>{` for setup of test businesses.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "eftpos-transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#eftpos-transactions",
        "aria-label": "eftpos transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EFTPOS transactions`}</h3>
    <p><strong parentName="p">{`For development machines`}</strong>{` The following test details should be used for all card payment transactions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Payment capture: magnetic stripe swipe (insert and contactless tap not supported)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payment type: Credit`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PIN: 0000`}</p>
        <p parentName="li">{` The TID and MID will be issued with the development machine.
`}<strong parentName="p">{`For simulator`}</strong>{` Set the following:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`TID: 1`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MID: any or set to same as HealthPoint MID`}</p>
        <p parentName="li">{`The simulator uses magic numbers to create different transaction outcomes.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Abbreviated list of magic numbers`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Amount`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Transaction type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Outcome`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$100.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved Visa`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$100.01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declined Visa`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$100.08`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Signature-verified purchase`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$112.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved (in EUR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$103.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved Mastercard`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$103.01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declined Mastercard`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$104.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved Amex`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$104.01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declined Amex`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
      </tbody>
    </table>
    <p>{` A full list of magic numbers with different outcomes can be found on the Tyro integration site: `}<a parentName="p" {...{
        "href": "https://integrationsimulator.test.tyro.com/docs/purchase"
      }}>{`https://integrationsimulator.test.tyro.com/docs/purchase`}</a></p>
    <h2 {...{
      "id": "healthpoint-transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#healthpoint-transactions",
        "aria-label": "healthpoint transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HealthPoint transactions`}</h2>
    <p><strong parentName="p">{`For development machines`}</strong>{` Testing requires use of test health fund cards - production cards cannot be used. For patient cardRank/ individual reference, use 1, 2, 3 or 4.
The TID and MID will be issued with the development machine.
`}<strong parentName="p">{`For simulator`}</strong>{` Set the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`TID: 1`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MID: 99922`}</p>
        <p parentName="li">{` For development machines and the simulator, use the following test providers and item codes. The simulator uses magic amounts and item numbers to create different transaction outcomes.`}</p>
        <br />
      </li>
    </ul>
    <p><strong parentName="p">{`Test providers`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First Name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last Name`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Any eligible provider profession from the approved list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2159081W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{any}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{any}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Any eligible provider profession from the approved list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2147661H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{any}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{any}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`---`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Abbreviated list of magic numbers`}</strong>{` `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Outcome`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Item Code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Item code price`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved, full benefit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any but special`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$99.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved, $101 gap`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any but special`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$201.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved, no benefit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any but special`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$101.00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Declined, general decline - code12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any but special`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$700.12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Declined, provider not approved - code 01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any but special`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$700.01`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><strong parentName="p">{`Special item codes`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Outcome`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Item Code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Item code price`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rebate of $10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`103`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rejected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`221`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional data required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`222`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rebate of $91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`A full list of test cases and error code scenarios is posted at: `}<a parentName="p" {...{
        "href": "https://integrationsimulator.test.tyro.com/docs/healthpoint#ClaimScenario"
      }}>{`https://integrationsimulator.test.tyro.com/docs/healthpoint#ClaimScenario`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      